import $ from 'jquery';

const isKerberosProtected = target => {
  // return target.match('/kerberos/') !== null;
  return true;
};

const performLogout = () => {
  console.log('invoking logout behavior');
  const logoutUrl = '/admin/logout.shtml';
  const kerberosFrame = document.querySelector('#kerberos-frame');
  kerberosFrame.src = logoutUrl;
  return new Promise(res => {
    kerberosFrame.addEventListener('load', () => {
      res();
    });
  });
};

const attemptKerberosLoginViaHiddenIframe = () => {
  const ifr = document.createElement('iframe');
  ifr.id = 'kerberos-frame';
  ifr.src = '/admin/kerberos/login.shtml';
  ifr.hidden = true;
  return new Promise((res, rej) => {
    document.body.appendChild(ifr);
    ifr.addEventListener(
      'load',
      () => {
        const errorCode = ifr.contentDocument.documentElement.dataset.errorCode;
        if (errorCode === '401') {
          performLogout().then(() => {
            rej();
          });
        }
        const foundUserid = ifr.contentDocument.body.dataset.userid;
        if (foundUserid) {
          res(foundUserid);
        } else {
          rej();
        }
      },
      { once: true }
    );
  });
};

const attemptKerberosLoginViaAjax = () => {
  const ajax = $.get('/admin/kerberos/login.shtml');
  return new Promise((res, rej) => {
    ajax.then(resp => {
      console.log('successful ajax', res);
      res(resp);
    });
    ajax.fail(err => {
      console.log('unsuccessful ajax', err);
      rej(err);
    });
  });
};

const navigateToPage = target => {
  window.location.href = target;
};

const showKerberosLogin = (userid, target) => {
  $('#LOGINFORM').hide();
  $('.login-text.userid').text(userid);
  $('.kerberos-login').show();
  const loginButton = document.querySelector('.kerberos-login button');
  if (loginButton) {
    loginButton.focus();
    loginButton.addEventListener('click', () => {
      navigateToPage(target);
    });
    const normalSignIn = document.querySelector(
      '.kerberos-user-id-sign-in-link'
    );
    normalSignIn.addEventListener('click', () => {
      hideKerberosLogin();
    });
  }
};

const hideKerberosLogin = () => {
  $('.kerberos-login').hide();
  $('#LOGINFORM').show();
  document.querySelector('#USER').focus();
};

const isValidUserId = userid => {
  if (userid === '(none)') {
    return false;
  }
  return true;
};

const isKeyfobLoginPage = !!window.location.href.match('foblogin');

function shouldAttemptKerberosAuth() {
  return window.upLogin && window.upLogin.TARGET && !isKeyfobLoginPage;
}

const isAppId = userid => {
  return userid && userid.match(/^d/i);
};
if (shouldAttemptKerberosAuth()) {
  const hash = document.location.hash;
  const target = `${window.upLogin.TARGET}${hash}`;
  if (isKerberosProtected(target)) {
    const useIframe = true;
    const method = useIframe
      ? attemptKerberosLoginViaHiddenIframe
      : attemptKerberosLoginViaAjax;
    method()
      .then(userid => {
        if (isValidUserId(userid)) {
          showKerberosLogin(userid, target);
        }
        if (isAppId(userid)) {
          navigateToPage(target);
        }
      })
      .catch(e => {
        console.error('unable to kerberos', e);
      });
  }
}
