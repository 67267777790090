import $errorAlert from './error-alert';

class ErrorAlert {
  show(reason) {
    $errorAlert.trigger('errorWindow.show', reason);
  }

  showMessage(message) {
    $errorAlert.trigger('errorWindow.showMessage', message)
  }

  hide() {
    $errorAlert.trigger('errorWindow.hide');
  }

  toggle() {
    $errorAlert.trigger('errorWindow.toggle');
  }
};

const errorAlert = new ErrorAlert();
export default errorAlert;
