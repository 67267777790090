import $ from 'jquery';

$(() => {
  // iOS devices have issues when attempting to focus on input fields,
  // so we have to do a UA sniff to prevent that from occurring.
  const focusBlacklist = ['iPhone', 'iPad'];
  const ua = navigator.userAgent;
  const blacklistedDevice = focusBlacklist.some(e => ua.match(e));

  if (!blacklistedDevice) {
    document.getElementById('USER').focus();
  }
});

