import $ from 'jquery';

$(() => {
  const $loadingElements = $('.full-loading');
  const $errorAlert = $('.rc-error-alert');

  $(document).on('login.submit', evt => {
    $loadingElements.removeClass('hidden');
  });

  $errorAlert.on('errorWindow.show', () => {
    $loadingElements.addClass('hidden');
  });
});
