import $ from 'jquery';

$(function() {
    // send info about the browser
    $.get("/admin/ssl/survey_screen.gif", {
        width: window.screen.width,
        height: window.screen.height,
        colorDepth: window.screen.colorDepth
    });
});
