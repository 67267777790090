// A hack to append hashes to the target.
// Mostly used for single-page (Angular) apps.
// Appears to be only required for IE11 now
const $target = document.forms['LOGINFORM']['target'];
const containsHash =
  $target && document.location.hash && !$target.value.match('#');
const isIE11 = typeof fetch === 'undefined';
const shouldAddHashToTarget = containsHash && isIE11;
if (shouldAddHashToTarget) {
  console.log('adding current URL hash to target');
  $target.value += document.location.hash;
}
