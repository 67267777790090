import $ from 'jquery';

export const triggerSubmitAffordance = () => {
  $(document).trigger('login.submit');
};

$(() => {
  $('#LOGINFORM').on('submit', evt => {
    triggerSubmitAffordance();
  });
});
