const isRetireesHost = () => {
  const host = window.location.hostname;
  const retireesHosts = [
    'retirees.www.uprr.com',
    'sapext.',
    'sapretireesq.',
    'sapretireesn.'
  ];
  for (let i = 0; i < retireesHosts.length; i++) {
    if (!!host.match(retireesHosts[i])) {
      return true;
    }
  }
  return false;
};

if (isRetireesHost()) {
  const forgotPasswordUrl =
    'https://retirees.www.uprr.com/iae_pwm/jas/retiree/private/ChangePassword';
  const forgotLink = document.querySelector('.forgot a');
  forgotLink.href = forgotPasswordUrl;

  const userLabel = document.querySelector('label[for="USER"]');
  userLabel.innerHTML = 'User ID or Employee ID:';
}
