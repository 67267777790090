import $ from 'jquery';

let $errorAlert;
$errorAlert = $('.rc-error-alert');
if (!__IS_HWC__) { $errorAlert.hide(); }

const errorMessages = {
  failure: 'Your user id or password is incorrect.',
  unknown: 'Your login attempt failed. Please try again.'
};

$errorAlert.on('errorWindow.show', (_, reason) => {
  $errorAlert.find('.error-alert-message').text(errorMessages[reason]);
  $errorAlert.show();
});
// For long or rich HTML error messages, provide the capacity to display HTML in $rcAlert.
$errorAlert.on('errorWindow.showMessage', (_, message) => {
  $errorAlert.find('.error-alert-message').html(message);
  $errorAlert.show();
});

$errorAlert.on('errorWindow.hide', () => {
  $errorAlert.hide();
});

$errorAlert.on('errorWindow.toggle', () => {
  $errorAlert.toggle();
});

export default $errorAlert;
